define("discourse/plugins/stemaway-tiered-tagging/discourse/initializers/modify-topic-category", ["exports", "discourse/lib/plugin-api", "discourse/plugins/stemaway-tiered-tagging/discourse/lib/tag-modify-helpers"], function (_exports, _pluginApi, _tagModifyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-topic-category',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11.0', api => {
        api.modifyClass('component:topic-category', {
          pluginId: 'stemaway-tiered-tagging',
          didRender() {
            this._super(...arguments);
            this.addTagsToTopic2();
          },
          addTagsToTopic2() {
            const topic = this.topic;
            if (!topic) return;
            const element2 = this.element;
            const topic_category = element2.closest('.topic-category');
            if (topic_category.querySelector('.custom-tags')) return;
            topic_category.style.flexDirection = 'column';
            topic_category.style.alignItems = 'flex-start';
            const existingTagsElement2 = element2.querySelector('.list-tags');
            if (existingTagsElement2) {
              existingTagsElement2.remove();
            }
            const topic_header = element2.querySelector('.topic-header-extra');
            if (topic_header && topic_category) {
              const customTagsContainer2 = document.createElement('div');
              customTagsContainer2.className = 'custom-tags';
              customTagsContainer2.style.alignItems = 'left';
              (0, _tagModifyHelpers.appendTags)(customTagsContainer2, 'Domain', topic.pathway, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px',
                  marginBottom: '2px'
                }
              });
              (0, _tagModifyHelpers.appendTags)(customTagsContainer2, 'Subdomain', topic.skill, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px',
                  marginBottom: '2px'
                }
              });
              (0, _tagModifyHelpers.appendTags)(customTagsContainer2, 'Focus', topic.subSkill, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px',
                  marginBottom: '2px'
                }
              });
              topic_category.appendChild(customTagsContainer2);
            }
          }
        });
      });
    }
  };
});