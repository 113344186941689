define("discourse/plugins/stemaway-tiered-tagging/discourse/initializers/modify-topic-list-body", ["exports", "discourse/lib/plugin-api", "discourse/plugins/stemaway-tiered-tagging/discourse/lib/tag-modify-helpers"], function (_exports, _pluginApi, _tagModifyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-topic-list-item',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11.0', api => {
        api.modifyClass('component:topic-list-item', {
          pluginId: 'stemaway-tiered-tagging',
          didRender() {
            this._super(...arguments);
            this.addTagsToTopic();
          },
          addTagsToTopic() {
            const topic = this.topic;
            if (!topic) return;
            const element = this.element;
            const linkBottomLine = element.querySelector('.link-bottom-line');
            const existingTagsElement = element.querySelector('.discourse-tags');
            if (linkBottomLine) {
              if (existingTagsElement) {
                existingTagsElement.remove();
              }
              linkBottomLine.style.marginBottom = '5px';
              const customTagsContainer = document.createElement('div');
              customTagsContainer.className = 'custom-tags';
              (0, _tagModifyHelpers.appendTags)(customTagsContainer, 'Domain', topic.pathway, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px'
                }
              });
              (0, _tagModifyHelpers.appendTags)(customTagsContainer, 'Subdomain', topic.skill, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px'
                }
              });
              (0, _tagModifyHelpers.appendTags)(customTagsContainer, 'Focus', topic.subSkill, {
                tagStyle: {
                  padding: '1px 5px',
                  fontSize: '0.85em',
                  marginRight: '4px'
                }
              });
              linkBottomLine.insertAdjacentElement('afterend', customTagsContainer);
            }
          }
        });
      });
    }
  };
});