define("discourse/plugins/stemaway-tiered-tagging/discourse/lib/tag-modify-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.appendTags = appendTags;
  _exports.createTagElements = createTagElements;
  function createTagElements(label, values) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const wrapperElem = document.createElement("div");
    wrapperElem.className = "custom-tag-label";
    wrapperElem.textContent = `${label}: `;
    if (opts.wrapperStyle) {
      Object.assign(wrapperElem.style, opts.wrapperStyle);
    }
    values.forEach(value => {
      const tagElem = document.createElement("a");
      tagElem.className = "discourse-tag box";
      tagElem.textContent = value;
      tagElem.href = `/tag/${value}`;
      if (opts.tagStyle) {
        Object.assign(tagElem.style, opts.tagStyle);
      }
      wrapperElem.appendChild(tagElem);
    });
    return wrapperElem;
  }
  function appendTags(container, label, value, opts) {
    if (value) {
      const values = String(value).split(',').map(v => v.trim()).filter(Boolean);
      if (values.length > 0) {
        const tagElement = createTagElements(label, values, opts);
        container.appendChild(tagElement);
      }
    }
  }
});